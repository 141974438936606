import React, { Component } from "react";
import { graphql, withPrefix } from "gatsby"
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import ContactAudit from "../components/ContactAudit";
import ContactIparagFuggetlen from "../components/ContactIparagFuggetlen";
import Img from "gatsby-image/withIEPolyfill";

import { Panel, Modal } from "rsuite";
import { Link, FormattedMessage } from "gatsby-plugin-intl";


class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.openContact = this.openContact.bind(this);
    this.closeContact = this.closeContact.bind(this);
    this.formSwitch = this.formSwitch.bind(this);
  }

  closeContact() {
    this.setState({ show: false });
  }
  openContact() {
    this.setState({ show: true });
  }

  formSwitch(form) {
    switch (form) {
      case "audit":
        return <ContactAudit />;
      case "fuggetlen":
        return <ContactIparagFuggetlen />;
      default:
        return null;
    }
  }

  render() {
    const { title, form, oneline, problem, solution, icons, ident } = this.props.data.markdownRemark.frontmatter;
    const { html } = this.props.data.markdownRemark;
    const allicons = this.props.data.allIconsJson.edges;
    return (
      <Layout bodyClass="page-service" path={this.props.location.pathname}>
        <SEO title={title} />
        <div className="strip strip-white strip-diagonal">
          <div className="container pt-4 pt-md-10">
            <div className="row justify-content-start">
              <div className={html !== "" ?
                ("col-12 col-md-5 order-md-2 service-info-panel mb-3 mb-md-0") :
                ("col-12 order-md-2 service-info-panel mb-5 mb-md-0")
              }>
                <Panel shaded bordered className="bg-light-green text-center">
                  <h3 className={html !== "" ? ("d-none") : ("pb-3")}>{title}</h3>
                  <p className="h5">{oneline}</p>
                  <div className="service-icons center">
                    {icons?.split(',').map((icon, i) => {
                      let match_icon = allicons.find(el => el.node.title == icon);
                      return match_icon ? (
                        <div key={i} className="service-icon center">
                          <Img style={{ height: "2.8rem", width: "2.8rem" }} className="icon"
                            title={match_icon.node.desc}
                            objectFit="contain"
                            fixed={match_icon.node.image.childImageSharp.fixed} />
                        </div>
                      ) : null;
                    }
                    )}
                  </div>
                  <p>{problem}</p>
                  <p>{solution}</p>
                  <p>{oneline}</p>
                  <button onClick={e => this.openContact(e)} className="button">
                    <FormattedMessage id="Interest" />
                  </button>
                </Panel>
              </div>
              <div className={html !== "" ? ("col-12 col-md-7") : ("d-none")}>
                <div className="service service-single">
                  <h1 className="title mb-3">{title}</h1>
                  <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal size="md" backdrop="static" overflow={true} className="service-modal" show={this.state.show} onHide={this.closeContact}>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage id="Contact" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {form ? (
              this.formSwitch(form)
            ) : (
                <>
                  <h4 className="title mb-2">{title}</h4>

                  <form
                    name="contact"
                    method="post"
                    action="/success"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="form-group">
                      <label htmlFor="name"><FormattedMessage id="Name" /></label>
                      <input type="text" name="name" id="name" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email"><FormattedMessage id="Email" /></label>
                      <input type="email" name="email" id="email" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone"><FormattedMessage id="Phone" /></label>
                      <input type="tel" name="phone" id="phone" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message"><FormattedMessage id="Message" /></label>
                      <textarea name="message" id="message" rows="2" className="form-control"></textarea>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-8">
                        <div className="custom-control custom-checkbox">
                          <input required type="checkbox" className="custom-control-input" id="aszfcheck_interest" name="aszfcheck_interest"
                          />
                          <label className="custom-control-label" htmlFor="aszfcheck_interest">
                            <FormattedMessage id="Accept_the" />
                            <Link to="/terms/adatvedelem"><FormattedMessage id="T&C_link" /></Link>
                          </label>
                        </div>
                      </div>
                    </div>
                    <input type="hidden" name="servicename" value={title} />
                    <button type="submit" className="button float-right">
                      <FormattedMessage id="Submit" />
                    </button>
                  </form>
                </>
              )}

          </Modal.Body>
        </Modal>
      </Layout>
    )
  }

};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ident
        path
        title
        featured
        customer
        date(formatString: "DD MMMM YYYY")
        form
        img {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        active
        lang
        desc
        keywords
        oneline
        for
        problem
        solution
        value
        lenght
        price_from
        price_from_info
        price_to
        price_to_info
        icons
        connected
      }
      html
    }
    allIconsJson {
      edges {
        node {
          id
          title
          image {
            publicURL
            childImageSharp {
              fixed(width: 40) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;

export default Service;
