import React from "react";

import { useIntl, Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"

const ContactAudit = () => {
	const intl = useIntl()
	return (
		<div className="form mt-5">
			<form
				name="audit"
				method="post"
				action="/success"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
			>
				<input type="hidden" name="form-name" value="audit" />
				<h3 className="text-center pb-3"><FormattedMessage id="Ask_for_audit" /></h3>
				<div className="row">
					<div className="col-md-6 mb-3">
						<label htmlFor="nev"><FormattedMessage id="Full_name" /></label>
						<input
							className="form-control"
							placeholder={intl.formatMessage({ id: "how_should_we_call_you?" })}
							name="nev"
							id="nev"
							type="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="email"><FormattedMessage id="Email" />:</label>
						<input
							className="form-control"
							placeholder={intl.formatMessage({ id: "example@" })}
							name="email"
							id="email"
							type="email"
						/>
					</div>
					<div className="col-12 mb-3">
						<label htmlFor="ceg"><FormattedMessage id="Company_name?" /></label>
						<input
							className="form-control"
							placeholder={intl.formatMessage({ id: "company_name" })}
							name="ceg"
							id="ceg"
							type="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="email"><FormattedMessage id="Phone_number" /></label>
						<input
							className="form-control"
							placeholder="+36 70 362 0596"
							name="telefon"
							id="telefon"
							type="text"
						/>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="email"><FormattedMessage id="Scope_of_activities" /></label>
						<input
							className="form-control"
							placeholder={intl.formatMessage({ id: "example@" })}
							name="tevekenyseg"
							id="tevekenyseg"
							type="text"
						/>
					</div>

					<h4 className="col-12"><FormattedMessage id="About_the_site" /></h4>
					<div className="col-md-6 mb-3">
						<label htmlFor="telep"><FormattedMessage id="Number_of_sites" /></label>
						<input
							className="form-control"
							placeholder="3"
							name="telep"
							id="telep"
							type="number"
						/>
						<small id="teleplHelp" className="form-text text-muted">
							<FormattedMessage id="Number_of_sites_comment" />
						</small>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="meret">
							<FormattedMessage id="Size_of_sites" /> [m<sup>2</sup>]
						</label>
						<input
							className="form-control"
							placeholder="1200"
							name="meret"
							id="meret"
							type="text"
						/>
						<small id="meretHelp" className="form-text text-muted">
							<FormattedMessage id="Size_of_sites_comment" /> [m<sup>2</sup>]
						</small>
					</div>

					<h4 className="col-12"><FormattedMessage id="Consumption_data" /></h4>
					<div className="col-md-6 mb-3">
						<label htmlFor="villamos">
							<FormattedMessage id="Electrical_energy" /> [kWh]
						</label>
						<input
							className="form-control"
							placeholder="46 000"
							name="villamos"
							id="villamos"
							type="text"
						/>
						<small id="villamosHelp" className="form-text text-muted">
							<FormattedMessage id="Electrical_energy_comment" />
						</small>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="foldgaz">
							<FormattedMessage id="NG_consumption" /> [m<sup>3</sup>]
						</label>
						<input
							className="form-control"
							placeholder="26 100"
							name="foldgaz"
							id="foldgaz"
							type="text"
						/>
						<small id="foldgazHelp" className="form-text text-muted">
							<FormattedMessage id="NG_consumption_comment" /> [m<sup>3</sup>]
						</small>
					</div>
					<hr />
					<div className="col-md-6 mb-3">
						<label htmlFor="biomassza"><FormattedMessage id="Biomass_consumption" /></label>
						<input
							className="form-control"
							placeholder="950"
							name="biomassza"
							id="biomassza"
							type="text"
						/>
						<small id="biomasszaHelp" className="form-text text-muted">
							<FormattedMessage id="Biomass_consumption_comment" />
						</small>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="uzemanyag"><FormattedMessage id="Fuel_consumption" /></label>
						<input
							className="form-control"
							placeholder="950"
							name="uzemanyag"
							id="uzemanyag"
							type="text"
						/>
						<small id="uzemanyagaHelp" className="form-text text-muted">
							<FormattedMessage id="Fuel_consumption_comment" />
						</small>
					</div>
				</div>

				<div className="row">
					<div className="form-group col-md-8">
						<div className="custom-control custom-checkbox">
							<input
								required
								type="checkbox"
								className="custom-control-input"
								id="aszfcheck"
								name="aszfcheck"
							/>
							<label className="custom-control-label" htmlFor="aszfcheck">
								<FormattedMessage id="Accept_the" />
								<Link to="/terms/adatvedelem"><FormattedMessage id="T&C_link" /></Link>
							</label>
						</div>
					</div>

					<div className="form-group col-md-4">
						<div className="float-right mt-2">
							<button role="submit" className="button">
								<FormattedMessage id="Submit" />
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

export default ContactAudit
